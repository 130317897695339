import React from "react";
import './css/subheading.css';


function SubHeading() {
    
    return (
        <div className="heading" >
        <h2 className="script-font subheading">Favourite Inventive Bakes</h2>
        </div>
    );
}

export default SubHeading;
